import React, { ReactElement } from 'react'
import Loader from 'react-loader-spinner'

import { loader } from './LoaderSpinnerIcon.module.scss'

const LoaderSpinnerIcon = (): ReactElement => {
  return (
    <div className={loader}>
      <Loader type="Oval" color="rgba(255, 255, 255, 0.85)" height={24} width={24} />
    </div>
  )
}

export default LoaderSpinnerIcon
