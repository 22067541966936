import AwesomeDebouncePromise from 'awesome-debounce-promise'
import classNames from 'classnames'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import throttleFetch from 'external/rp.ui/utils/throttleFetch'
import { navigate, withPrefix } from 'gatsby'
import React, { ChangeEvent, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  change,
  Field,
  FormErrors,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  submit,
  WrappedFieldProps,
} from 'redux-form'
import TextButton from 'shared/components/Buttons/TextButton'
import CustomAutocompleteInput from 'shared/components/CustomAutocompleteInput'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import CustomInput from 'shared/components/CustomInput'
import emailValidationCheck from 'shared/utils/emailValidationCheck'

import useAutocomplete, { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete'

import {
  errorRequiredField,
  invalidRequiredFieldEmail,
  onlyAlphabeticCharacters,
  passwordMismatch,
  passwordMustBeAtLeast,
} from '../../constants/validationText'
import {
  CountrySearchResults,
  ICountrySearchResult,
  ICountrySearchResults,
  IRegistrationForm,
} from '../../proto/models'
import { register } from '../../reducers/registration/registrationReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'
import { formatingPhone, preparePhone } from '../../utils/formatingAndPreparePhone'
import {
  sectionBlockAgree,
  sectionBlockAgreeCheckbox,
  sectionBlockAgreeCheckboxText,
  sectionBlockButton,
  sectionBlockInputs,
  sectionBlockOne,
  sectionBlockTwo,
  sectionBlockTwoInput,
  sectionBlockTwoPhone,
  sectionForm,
  sectionLink,
  sectionLinkDisabled,
  sectionText,
} from './Registration.module.scss'

const onSearchValueChange = async (searchString: string): Promise<ICountrySearchResult[]> => {
  const url = 'user-registration/search-country'

  if (!searchString) {
    return []
  }

  const searchResult = await ProtoClient.get<ICountrySearchResults>(
    url,
    CountrySearchResults,
    {
      searchString,
    },
    'auth/api/'
  )

  return searchResult?.searchResult ?? []
}

const searchAPIDebounced = AwesomeDebouncePromise(onSearchValueChange, 500)

const linkDictionary = {
  ['россия']: {
    eulaLink: 'https://teq.ai/static/ru/term-web.pdf',
    privacyLink: 'https://teq.ai/static/ru/privacy-web.pdf',
  },
  ['казахстан']: {
    eulaLink: 'https://teq.ai/static/kz/term-web.pdf',
    privacyLink: 'https://teq.ai/static/kz/privacy-web.pdf',
  },
}

type knownCountries = 'россия' | 'казахстан'

const privacyLink = (country: ICountrySearchResult): string => {
  const countryName = country?.name?.toLowerCase() as knownCountries

  return linkDictionary[countryName]?.privacyLink
}

const eulaLink = (country: ICountrySearchResult): string => {
  const countryName = country?.name?.toLowerCase() as knownCountries

  return linkDictionary[countryName]?.eulaLink
}

interface ICountryFieldProps {
  label: string
  disabled: boolean
  placeholder: string
  onCustomChange: (country: ICountrySearchResult) => void
}

const CountryField = (props: ICountryFieldProps & WrappedFieldProps) => {
  const [options, setOptions] = useState<ICountrySearchResult[]>([])
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)

  const onInputChange = async (event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      const res = await searchAPIDebounced(value)
      setOptions(res)
      setAutocompleteOpen(true)
    }
    if (reason === 'clear') {
      setOptions([])
    }
  }

  const {
    input: { onChange },
  } = props

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    multiple: false,
    freeSolo: true,
    open: autocompleteOpen,
    onOpen: () => setAutocompleteOpen(true),
    onClose: () => setAutocompleteOpen(false),
    onInputChange: onInputChange,
    getOptionSelected: (option, value) => option.name === value.name,
    options: options,
    filterOptions: (options) => options,
    getOptionLabel: (option) => option.name ?? '',
    value: props.input.value,
    onChange: (event, value) => {
      onChange(value)
      props.onCustomChange(value)
    },
  })

  return (
    <CustomAutocompleteInput
      meta={props.meta}
      groupedOptions={groupedOptions}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      getListboxProps={getListboxProps}
      getOptionProps={getOptionProps}
      getOptionLabel={(option) => option.name}
      placeholder={props.placeholder}
      type="text"
      disabled={props.disabled}
      input={props.input}
      label={props.label}
    />
  )
}

const submitForm = throttleFetch((dispatch: Dispatch) => {
  dispatch(submit('registration'))
})

interface IRegistrationOwnProps {
  id?: string
}

interface IRegistrationStateProps {
  country?: ICountrySearchResult
  dispatch: AppDispatch
  changeCode?: (code: string) => void
}

type RegistrationProps = IRegistrationOwnProps &
  IRegistrationStateProps &
  InjectedFormProps<IRegistrationForm, IRegistrationOwnProps>

const Registration = (props: RegistrationProps) => {
  const { handleSubmit, submitting } = props
  const [accepted, setAccepted] = useState(false)

  const handleChange = () => {
    setAccepted((prev) => !prev)
  }

  const submitDisabled = (): boolean => props.invalid || !accepted || submitting

  const onCountryChange = (country: ICountrySearchResult) => {
    props.changeCode(country?.callingCode)
  }

  return (
    <form onSubmit={handleSubmit} className={sectionForm}>
      <div className={sectionBlockInputs}>
        <div className={sectionBlockOne}>
          <Field
            name="surname"
            label="Фамилия"
            component={CustomInput}
            placeholder="Введите фамилию"
            isReserveLabelError={true}
            maxLength={50}
            size="large"
          />
        </div>
        <div className={sectionBlockTwo}>
          <div className={sectionBlockTwoInput}>
            <Field
              name="name"
              label="Имя"
              component={CustomInput}
              placeholder="Введите имя"
              isReserveLabelError={true}
              maxLength={50}
              size="large"
            />
          </div>
          <div className={sectionBlockTwoInput}>
            <Field
              name="middlename"
              label="Отчество"
              component={CustomInput}
              placeholder="Введите отчество"
              isReserveLabelError={true}
              maxLength={50}
              size="large"
            />
          </div>
        </div>
      </div>
      <div className={sectionBlockInputs}>
        <div className={sectionBlockOne}>
          <Field
            name="country"
            label="Страна"
            component={CountryField}
            placeholder="Укажите страну"
            isReserveLabelError={true}
            onCustomChange={onCountryChange}
          />
        </div>
        <div className={sectionBlockTwo}>
          <div className={classNames(sectionBlockTwoInput, sectionBlockTwoPhone)}>
            <Field
              name="callingCode"
              label="Код"
              component={CustomInput}
              placeholder="7"
              isReserveLabelError={true}
              disabled
              size="large"
            />
          </div>
          <div className={sectionBlockTwoInput}>
            <Field
              name="phone"
              label="Телефон"
              component={CustomInput}
              placeholder="000-000-0000"
              isReserveLabelError={true}
              size="large"
              type="tel"
              format={formatingPhone}
              parse={preparePhone}
            />
          </div>
        </div>
      </div>
      <div className={sectionBlockInputs}>
        <div className={sectionBlockOne}>
          <Field
            name="email"
            label="Email"
            component={CustomInput}
            placeholder="Введите Email "
            isReserveLabelError={true}
            size="large"
          />
        </div>
        <div className={sectionBlockTwo}>
          <div className={sectionBlockTwoInput}>
            <Field
              name="password"
              label="Пароль"
              component={CustomInput}
              placeholder="Введите пароль"
              isReserveLabelError={true}
              type="password"
              size="large"
            />
          </div>
          <div className={sectionBlockTwoInput}>
            <Field
              name="checkpassword"
              label="Повторите пароль"
              component={CustomInput}
              placeholder="Повторите пароль"
              isReserveLabelError={true}
              type="password"
              size="large"
            />
          </div>
        </div>
      </div>
      <div className={sectionBlockAgree}>
        <div className={sectionBlockAgreeCheckbox}>
          <CustomCheckbox id="ageeCheck" checked={accepted} onChange={handleChange} name="ageeCheck" />
        </div>
        <div className={sectionBlockAgreeCheckboxText}>
          <p className={sectionText}>
            Я&nbsp;согласен с&nbsp;
            <a
              className={classNames(sectionLink, { [sectionLinkDisabled]: !props.country })}
              href={privacyLink(props.country)}
              target="_blank"
              rel="noreferrer"
            >
              политикой обработки персональных данных
            </a>{' '}
            и&nbsp;
            <a
              className={classNames(sectionLink, { [sectionLinkDisabled]: !props.country })}
              href={eulaLink(props.country)}
              target="_blank"
              rel="noreferrer"
            >
              пользовательским соглашением
            </a>
          </p>
        </div>
      </div>
      <div className={sectionBlockButton}>
        <TextButton
          disabled={submitDisabled()}
          onClick={() => submitForm(props.dispatch)}
          text="Зарегистрироваться"
          fullWidth={true}
          size="big"
        />
      </div>
    </form>
  )
}

const numberRegExp = new RegExp('\\d')

const validate = (values: IRegistrationForm): FormErrors<IRegistrationForm, string> => {
  const errors: FormErrors<IRegistrationForm, string> = {}

  if (!values.name) {
    errors.name = errorRequiredField
  }

  if (values.name && numberRegExp.test(values.name)) {
    errors.name = onlyAlphabeticCharacters
  }

  if (!values.surname) {
    errors.surname = errorRequiredField
  }

  if (values.surname && numberRegExp.test(values.surname)) {
    errors.surname = onlyAlphabeticCharacters
  }

  if (values.middlename && numberRegExp.test(values.middlename)) {
    errors.middlename = onlyAlphabeticCharacters
  }

  if (!values.callingCode) {
    errors.callingCode = errorRequiredField
  }

  if (!values.email) {
    errors.email = errorRequiredField
  } else if (!emailValidationCheck(values.email)) {
    errors.email = invalidRequiredFieldEmail
  }

  if (!values.password) {
    errors.password = errorRequiredField
  }

  if (values.password !== values.checkpassword) {
    errors.checkpassword = passwordMismatch
  }

  if (values.password?.length < 6) {
    errors.password = passwordMustBeAtLeast
  }

  if (!values.country) {
    errors.country = errorRequiredField
  }

  return errors
}

const registrationReduxForm = reduxForm<IRegistrationForm, RegistrationProps>({
  form: 'registration',
  validate,
  onSubmit: async (values, dispatch, props) => {
    if (props.submitting) {
      return
    }
    await dispatch(register(props.id, values))
    navigate(withPrefix('/'))
  },
})(Registration)

const selector = formValueSelector('registration')

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    changeCode: (code: string) => {
      dispatch(change('registration', 'callingCode', code))
    },
  }
}

const mapStateToProps = (store: IAppState): IRegistrationStateProps => {
  const country = selector(store, 'country')

  return {
    country: country,
    dispatch: store.dispatch,
  }
}

export default connect<IRegistrationStateProps, {}, IRegistrationOwnProps, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(registrationReduxForm)
