import React, { ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'
import Logo from 'shared/components/Icons/Logo'
import LoaderSpinner from 'shared/components/LoaderSpinner'

import { checkUserInviteExist } from '../../reducers/registration/registrationReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'
import Registration from './Registration'
import { section, sectionBlockLogo, sectionLogo, sectionTitle, sectionWrapper } from './Registration.module.scss'

interface IRegistrationPageOwnProps {
  path: string
  id?: string
}

interface IRegistrationPageStateProps {
  checkInProgress: boolean
  dispatch: AppDispatch
}

type RegistrationPageProps = IRegistrationPageOwnProps & IRegistrationPageStateProps

const RegistrationPage = (props: RegistrationPageProps): ReactElement<RegistrationPageProps> => {
  useEffect(() => {
    props.dispatch(checkUserInviteExist(props.id))
  }, [])

  return props.checkInProgress ? (
    <LoaderSpinner />
  ) : (
    <section className={section}>
      <div className={sectionWrapper}>
        <div className={sectionBlockLogo}>
          <Logo class={sectionLogo} />
        </div>
        <h1 className={sectionTitle}>Регистрация</h1>
        <Registration id={props.id} />
      </div>
    </section>
  )
}

const mapStateToProps = (store: IAppState): IRegistrationPageStateProps => {
  return {
    checkInProgress: store.registration.checkInProgress,
    dispatch: store.dispatch,
  }
}

export default connect<IRegistrationPageStateProps, {}, IRegistrationPageOwnProps, IAppState>(mapStateToProps)(
  RegistrationPage
)
