import { Action, combineReducers, Dispatch } from 'redux'
import { FormReducer, reducer as formReducer } from 'redux-form'

import { ThunkDispatch } from '@reduxjs/toolkit'

import portalReducer, { IPortalState } from './portalReducer'
import registrationReducer, { IRegistrationState } from './registration/registrationReducer'

export type AppDispatch = ThunkDispatch<IAppState, {}, Action>

export interface IAppState {
  dispatch: Dispatch
  registration: IRegistrationState
  portal: IPortalState
  form: FormReducer
}

export const rootReducer = combineReducers({
  registration: registrationReducer,
  portal: portalReducer,
  form: formReducer,
})
