import classNames from 'classnames'
import React, { ReactElement } from 'react'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'

import {
  blockCustomInput,
  customInput,
  customInputInput,
  customInputInputError,
  customInputNormal,
  customInputSmall,
  icon,
  iconArrownDown,
  iconMagnifier,
  labelError,
  list,
  listItem,
  listText,
  wrapper,
} from './CustomAutocompleteInput.module.scss'

interface ICustomAutocompleteInputProps {
  icon?: 'magnifier' | 'arrow-down'
  meta?: WrappedFieldMetaProps
  placeholder?: string
  type: string
  getRootProps?: () => {}
  getInputProps?: () => {}
  groupedOptions?: any[]
  getListboxProps?: () => {}
  getOptionLabel?: (option: any) => string
  getOptionProps?: ({ option, index }: { option: any; index: number }) => {}
  maxHeightResult?: number
  disabled?: boolean
  size?: 'small' | 'normal'
  isReserveLabelError?: boolean
  label?: string
  input?: WrappedFieldInputProps
}

const CustomAutocompleteInput = (props: ICustomAutocompleteInputProps): ReactElement<ICustomAutocompleteInputProps> => {
  let meta: string
  const { isReserveLabelError = true, label } = props
  const isError = props.meta?.touched

  if (isError && props.meta?.error) {
    meta = props.meta.error
  }

  if (isError && props.meta?.warning) {
    meta = props.meta.warning
  }

  const labelErrorClassName = classNames(label, {
    [labelError]: isError && (props.meta?.error || props.meta?.warning),
  })

  const size = props.size ?? 'large'

  const inputClassName = classNames(customInput, {
    [customInputSmall]: size === 'small',
    [customInputNormal]: size === 'normal',
  })

  return (
    <div className={blockCustomInput} {...props.getRootProps()}>
      {label && <label className={label}>{label}</label>}
      <div className={wrapper}>
        <span className={inputClassName}>
          <span
            className={classNames(icon, iconArrownDown, {
              [iconMagnifier]: props.icon === 'magnifier',
            })}
          ></span>
          <input
            {...props.input}
            {...props.getInputProps()}
            placeholder={props.placeholder}
            className={classNames(customInputInput, {
              [customInputInputError]: isError && (props.meta?.error || props.meta?.warning),
            })}
            type={props.type}
            disabled={props.disabled}
          />
        </span>
        {props.groupedOptions.length > 0 && (
          <ul className={list} {...props.getListboxProps()} style={{ maxHeight: props.maxHeightResult }}>
            {props.groupedOptions.map((option, index) => {
              return (
                <li key={index} className={listItem} {...props.getOptionProps({ option, index })}>
                  <span className={listText}>{props.getOptionLabel(option)}</span>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      {isReserveLabelError && <span className={labelErrorClassName}>{meta}&nbsp;</span>}
    </div>
  )
}

export default CustomAutocompleteInput
