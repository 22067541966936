// extracted by mini-css-extract-plugin
export var buttonText = "TextButton-module--button-text--2g25x";
export var buttonTextOutlined = "TextButton-module--button-text-outlined--3v_5d";
export var buttonTextWhite = "TextButton-module--button-text-white--e3vNx";
export var buttonTextBigger = "TextButton-module--button-text-bigger--LbqYt";
export var buttonTextIconRight = "TextButton-module--button-text-icon-right--3VeOx";
export var buttonTextIconLeft = "TextButton-module--button-text-icon-left--1xaPm";
export var buttonTextLoading = "TextButton-module--button-text-loading--2oIa9";
export var buttonTextIcon = "TextButton-module--button-text-icon--1eQp8";
export var buttonTextSimple = "TextButton-module--button-text-simple--3m8aY";
export var buttonTextFullWidth = "TextButton-module--button-text-full-width--BvlZE";
export var buttonTextTextual = "TextButton-module--button-text-textual--1ekq8";