import { navigate, withPrefix } from 'gatsby'
import React, { ReactElement } from 'react'
import TextButton from 'shared/components/Buttons/TextButton'

import { Dialog } from '@material-ui/core'

import { modal, modalButton, modalTitle } from './ChangePassword.module.scss'

interface IChangePasswordModalProps {
  url?: string
  onClose: () => void
  open: boolean
}

const ChangePasswordModal = (props: IChangePasswordModalProps): ReactElement<IChangePasswordModalProps> => {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false}>
      <div className={modal}>
        <h3 className={modalTitle}>Ваш пароль успешно изменен</h3>
        <div className={modalButton}>
          <TextButton
            text="Войти в систему"
            fullWidth={true}
            size="big"
            onClick={() => {
              props.onClose()
              navigate(withPrefix('/'))
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ChangePasswordModal
