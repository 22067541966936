// extracted by mini-css-extract-plugin
export var sectionTitle = "Registration-module--section-title--2-A2J";
export var sectionLogo = "Registration-module--section-logo--3zcW6";
export var section = "Registration-module--section--KQ9Ag";
export var sectionWrapper = "Registration-module--section-wrapper--2tfbB";
export var sectionForm = "Registration-module--section-form--37cL3";
export var sectionBlockInputs = "Registration-module--section-block-inputs--R3gw8";
export var sectionBlockOne = "Registration-module--section-block-one--1JlnU";
export var sectionBlockTwo = "Registration-module--section-block-two--1vNTk";
export var sectionBlockTwoInput = "Registration-module--section-block-two-input--HlWcy";
export var sectionBlockTwoPhone = "Registration-module--section-block-two-phone--S4wql";
export var sectionBlockAgree = "Registration-module--section-block-agree--18iYA";
export var sectionBlockAgreeCheckbox = "Registration-module--section-block-agree-checkbox--2n3fZ";
export var sectionBlockAgreeCheckboxText = "Registration-module--section-block-agree-checkbox-text--sMQ_L";
export var sectionBlockButton = "Registration-module--section-block-button--1j-I-";
export var sectionBlockLogo = "Registration-module--section-block-logo--3LxLM";
export var sectionText = "Registration-module--section-text--2m9vL";
export var sectionLink = "Registration-module--section-link--1K8Ob";
export var sectionLinkDisabled = "Registration-module--section-link-disabled--33Ot-";