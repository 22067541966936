import { rootReducer } from 'auth/reducers/rootReducer'
import React, { ReactElement } from 'react'
import { Provider } from 'react-redux'

import { Router } from '@reach/router'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import ChangePasswordPage from '../ChangePassword'
import LoginPage from '../Login'
import RecoveryPage from '../Recovery'
import RecoveryProcessing from '../RecoveryProcessing'
import RegistrationPage from '../Registration'
import { content } from './App.module.scss'

const middleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
  thunk: true,
})

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV === 'development',
})

const App = (): ReactElement => {
  return (
    <Provider store={store}>
      <main className={content}>
        <Router>
          <LoginPage path="/" />
          <RegistrationPage path="registration/:id" />
          <RecoveryPage path="recovery" />
          <ChangePasswordPage path="change-password/:id" />
          <RecoveryProcessing path="recovery-processing" />
        </Router>
      </main>
    </Provider>
  )
}

export default App
