import classNames from 'classnames'
import { AuthService } from 'external/rp.ui/auth/services/authService'
import ClientOnly from 'external/rp.ui/components/clientOnly/clientOnly'
import { navigate, withPrefix } from 'gatsby'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import TextButton from '../../../shared/components/Buttons/TextButton'
import Logo from '../../../shared/components/Icons/Logo'
import LoaderSpinner from '../../../shared/components/LoaderSpinner'
import { IAvailableRoute } from '../../proto/models'
import { getOfficces, setLoading } from '../../reducers/portalReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'
import Login from './Login'
import {
  section,
  sectionExit,
  sectionExitButton,
  sectionInner,
  sectionLogo,
  sectionLogoImage,
  sectionTitle,
  sectionWrapper,
  sectionWrapperHidden,
  sectionWrapperPortal,
  sectionWrapperShow,
} from './LoginPage.module.scss'
import Portal from './Portal'

const marginTopLogo = 105
const heightLogo = 50

interface ILoginPageOwnProps {
  path: string
  url?: string
}

interface ILoginPageStateProps {
  dispatch: AppDispatch
  officces: IAvailableRoute[]
  loadingButton: boolean
  loading: boolean
}

type LoginPageProps = ILoginPageOwnProps & ILoginPageStateProps

const LoginPage = (props: LoginPageProps): ReactElement<LoginPageProps> => {
  const [showPortal, setShowPortal] = useState(false)
  const [transform, setTransform] = useState(0)
  const refLogin = useRef<HTMLDivElement>()

  const getData = (): Promise<IAvailableRoute[]> => {
    return props.dispatch(getOfficces())
  }

  const loadPortal = () => {
    setTransform(refLogin.current?.offsetHeight + marginTopLogo + heightLogo)
    setShowPortal(true)
  }

  const loadLogin = () => {
    setTransform(0)
    setShowPortal(false)
  }

  const authHandler = async () => {
    const items = await getData()
    if (items?.length === 1) {
      navigate(withPrefix(items[0].subroute))
    } else if (items?.length > 1) {
      loadPortal()
    }
  }

  useEffect(() => {
    props.dispatch(setLoading(true))
    authHandler()

    return () => {
      setShowPortal(false)
      setTransform(0)
    }
  }, [])

  return (
    <ClientOnly>
      <section className={section}>
        {props.loading ? (
          <LoaderSpinner />
        ) : (
          <div className={sectionInner} style={{ transform: `translateY(-${transform}px` }}>
            <div ref={refLogin} className={classNames(sectionWrapper, { [sectionWrapperHidden]: showPortal })}>
              <h1 className={sectionTitle}>Вход в систему</h1>
              <Login callbackAuth={() => authHandler()} loading={props.loadingButton} />
            </div>

            <div className={sectionLogo} style={{ marginTop: `${marginTopLogo}px` }}>
              <Logo class={sectionLogoImage} />
            </div>

            {props.officces.length > 1 && (
              <div
                className={classNames(sectionWrapper, sectionWrapperPortal, {
                  [sectionWrapperShow]: showPortal,
                })}
              >
                <h1 className={sectionTitle}>Доступные сервисы</h1>
                <Portal officces={props.officces} />
                <div className={sectionExit}>
                  <div className={sectionExitButton}>
                    <TextButton
                      text="Выход"
                      variant="text"
                      size="big"
                      fullWidth={true}
                      onClick={async () => {
                        try {
                          await AuthService.Logout()
                        } catch (err) {
                          loadLogin()
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </ClientOnly>
  )
}

const mapStateToProps = (store: IAppState): ILoginPageStateProps => {
  return {
    dispatch: store.dispatch,
    officces: store.portal.officces,
    loadingButton: store.portal.loadingButton,
    loading: store.portal.loading,
  }
}

export default connect<ILoginPageStateProps, never, ILoginPageOwnProps, IAppState>(mapStateToProps)(LoginPage)
