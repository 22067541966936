import { Dispatch } from 'redux'

import { AuthError, AuthHelper, HttpHelper, ProtoHelper } from 'external/rp.ui/utils/protoClient'
import { createAction, createReducer } from '@reduxjs/toolkit'

import { AvailableRoutes, IAvailableRoute, IAvailableRoutes } from '../proto/models'

const url = 'access'
const apiPrefix = '/auth/api/'

export interface IPortalState {
  officces: IAvailableRoute[]
  loading: boolean
  loadingButton: boolean
}

const initialState: IPortalState = {
  officces: [],
  loading: true,
  loadingButton: false,
}

const SET_LOADING = 'SET_LOADING'
export const setLoading = createAction<boolean>(SET_LOADING)

const SET_LOADING_BUTTON = 'SET_LOADING_BUTTON'
export const setLoadingButton = createAction<boolean>(SET_LOADING_BUTTON)

const getAvailableLinks = async (): Promise<IAvailableRoutes> => {
  const relativeUrl = url + '/available-links'
  const urlToRequest = HttpHelper.constructUrl(apiPrefix, relativeUrl)
  const options = ProtoHelper.setOptions(HttpHelper.defaultOptions)
  const request = HttpHelper.getBefore(urlToRequest, options, {})

  const response = await HttpHelper.sendRequest(request)

  if (AuthHelper.isAuthFailed(response)) {
    throw new AuthError()
  }

  return ProtoHelper.requestAfter<IAvailableRoutes>(response, AvailableRoutes)
}

const GET_OFFICCES = 'GET_OFFICCES'
export const getOfficces = () => async (dispatch: Dispatch): Promise<IAvailableRoute[]> => {
  try {
    const res = await getAvailableLinks()

    dispatch({ type: GET_OFFICCES, payload: res.items })

    return res.items
  } finally {
    dispatch({ type: SET_LOADING_BUTTON, payload: false })
    dispatch({ type: SET_LOADING, payload: false })
  }
}

export default createReducer(initialState, {
  [SET_LOADING]: (state, action) => {
    state.loading = action.payload
  },
  [SET_LOADING_BUTTON]: (state, action) => {
    state.loadingButton = action.payload
  },
  [GET_OFFICCES]: (state, action) => {
    state.officces = action.payload
  },
})
