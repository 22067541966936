// extracted by mini-css-extract-plugin
export var sectionErrorText = "LoginPage-module--section-error-text--3J9Go";
export var sectionTitle = "LoginPage-module--section-title--2Pvzs";
export var sectionLogoImage = "LoginPage-module--section-logo-image--sjkbA";
export var section = "LoginPage-module--section--2JJI8";
export var sectionInner = "LoginPage-module--section-inner--3FHuO";
export var sectionLogo = "LoginPage-module--section-logo--3fanb";
export var sectionWrapper = "LoginPage-module--section-wrapper--rmPoo";
export var sectionWrapperHidden = "LoginPage-module--section-wrapper-hidden--2vATt";
export var sectionWrapperPortal = "LoginPage-module--section-wrapper-portal--1XGpU";
export var sectionWrapperShow = "LoginPage-module--section-wrapper-show--1I2j2";
export var sectionForm = "LoginPage-module--section-form--Zs_Ny";
export var sectionBlockInput = "LoginPage-module--section-block-input--Uvnzl";
export var sectionBlockButton = "LoginPage-module--section-block-button--2MeEz";
export var sectionBlockLink = "LoginPage-module--section-block-link--1Ssy3";
export var sectionInput = "LoginPage-module--section-input--Bd_oi";
export var sectionError = "LoginPage-module--section-error--LGcO8";
export var sectionExit = "LoginPage-module--section-exit--2e2D0";
export var sectionExitButton = "LoginPage-module--section-exit-button--2ae2r";
export var portal = "LoginPage-module--portal--1onkh";
export var portalList = "LoginPage-module--portal-list--1Knal";
export var portalListThree = "LoginPage-module--portal-list-three--1Eez_";
export var portalItem = "LoginPage-module--portal-item--2L-88";
export var portalText = "LoginPage-module--portal-text--1ZhkD";
export var portalIcons = "LoginPage-module--portal-icons--2fj0j";
export var portalIconsPharmacyOffice = "LoginPage-module--portal-icons-pharmacyOffice--Ddwuq";
export var portalIconsManufacturerOffice = "LoginPage-module--portal-icons-manufacturerOffice--1Hksc";
export var portalIconsRegionalManagerOffice = "LoginPage-module--portal-icons-regionalManagerOffice--2UxIj";
export var portalIconsBackOffice = "LoginPage-module--portal-icons-backOffice--3eUlG";