import classNames from 'classnames'
import { Link, withPrefix } from 'gatsby'
import React, { ReactElement } from 'react'

import { ETeqServices, IAvailableRoute } from '../../proto/models'
import {
  portal,
  portalIcons,
  portalIconsBackOffice,
  portalIconsManufacturerOffice,
  portalIconsPharmacyOffice,
  portalIconsRegionalManagerOffice,
  portalItem,
  portalList,
  portalListThree,
  portalText,
} from './LoginPage.module.scss'

interface IOfficces {
  id: number
  title: string
  link: string
}

const mapOffices = new Map([
  [ETeqServices.BackOffice, 'Административная панель'],
  [ETeqServices.ManufacturerOffice, 'Кабинет производителя'],
  [ETeqServices.PharmacyOffice, 'Кабинет аптечной сети'],
  [ETeqServices.RegionalManagerOffice, 'Кабинет рег. менеджера'],
])

interface IPortalProps {
  officces: IAvailableRoute[]
}

const Portal = (props: IPortalProps): ReactElement => {
  const array: IOfficces[] = props.officces.map((item) => {
    return {
      id: item.teqService,
      title: mapOffices.get(item.teqService),
      link: item.subroute,
    }
  })

  const getClassName = (value: number) => {
    switch (value) {
      case ETeqServices.BackOffice:
        return classNames(portalIcons, portalIconsBackOffice)
      case ETeqServices.ManufacturerOffice:
        return classNames(portalIcons, portalIconsManufacturerOffice)
      case ETeqServices.PharmacyOffice:
        return classNames(portalIcons, portalIconsPharmacyOffice)
      case ETeqServices.RegionalManagerOffice:
        return classNames(portalIcons, portalIconsRegionalManagerOffice)
    }
  }

  return (
    <div className={portal}>
      <ul className={classNames(portalList, { [portalListThree]: props.officces.length === 3 })}>
        {array.map((item) => {
          return (
            <li key={item.id} className={portalItem}>
              <Link to={withPrefix(item.link)}>
                <span className={getClassName(item.id)}></span>
                <span className={portalText}>{item.title}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Portal
