/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots.auth || ($protobuf.roots.auth = {});
    
    $root.RegistrationForm = (function() {
    
        /**
         * Properties of a RegistrationForm.
         * @exports IRegistrationForm
         * @interface IRegistrationForm
         * @property {Uint8Array|null} [id] RegistrationForm id
         * @property {string|null} [name] RegistrationForm name
         * @property {string|null} [surname] RegistrationForm surname
         * @property {string|null} [middlename] RegistrationForm middlename
         * @property {string|null} [phone] RegistrationForm phone
         * @property {string|null} [email] RegistrationForm email
         * @property {string|null} [password] RegistrationForm password
         * @property {string|null} [checkpassword] RegistrationForm checkpassword
         * @property {ICountrySearchResult|null} [country] RegistrationForm country
         * @property {string|null} [callingCode] RegistrationForm callingCode
         */
    
        /**
         * Constructs a new RegistrationForm.
         * @exports RegistrationForm
         * @classdesc Represents a RegistrationForm.
         * @implements IRegistrationForm
         * @constructor
         * @param {IRegistrationForm=} [properties] Properties to set
         */
        function RegistrationForm(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * RegistrationForm id.
         * @member {Uint8Array} id
         * @memberof RegistrationForm
         * @instance
         */
        RegistrationForm.prototype.id = $util.newBuffer([]);
    
        /**
         * RegistrationForm name.
         * @member {string} name
         * @memberof RegistrationForm
         * @instance
         */
        RegistrationForm.prototype.name = "";
    
        /**
         * RegistrationForm surname.
         * @member {string} surname
         * @memberof RegistrationForm
         * @instance
         */
        RegistrationForm.prototype.surname = "";
    
        /**
         * RegistrationForm middlename.
         * @member {string} middlename
         * @memberof RegistrationForm
         * @instance
         */
        RegistrationForm.prototype.middlename = "";
    
        /**
         * RegistrationForm phone.
         * @member {string} phone
         * @memberof RegistrationForm
         * @instance
         */
        RegistrationForm.prototype.phone = "";
    
        /**
         * RegistrationForm email.
         * @member {string} email
         * @memberof RegistrationForm
         * @instance
         */
        RegistrationForm.prototype.email = "";
    
        /**
         * RegistrationForm password.
         * @member {string} password
         * @memberof RegistrationForm
         * @instance
         */
        RegistrationForm.prototype.password = "";
    
        /**
         * RegistrationForm checkpassword.
         * @member {string} checkpassword
         * @memberof RegistrationForm
         * @instance
         */
        RegistrationForm.prototype.checkpassword = "";
    
        /**
         * RegistrationForm country.
         * @member {ICountrySearchResult|null|undefined} country
         * @memberof RegistrationForm
         * @instance
         */
        RegistrationForm.prototype.country = null;
    
        /**
         * RegistrationForm callingCode.
         * @member {string} callingCode
         * @memberof RegistrationForm
         * @instance
         */
        RegistrationForm.prototype.callingCode = "";
    
        /**
         * Creates a new RegistrationForm instance using the specified properties.
         * @function create
         * @memberof RegistrationForm
         * @static
         * @param {IRegistrationForm=} [properties] Properties to set
         * @returns {RegistrationForm} RegistrationForm instance
         */
        RegistrationForm.create = function create(properties) {
            return new RegistrationForm(properties);
        };
    
        /**
         * Encodes the specified RegistrationForm message. Does not implicitly {@link RegistrationForm.verify|verify} messages.
         * @function encode
         * @memberof RegistrationForm
         * @static
         * @param {IRegistrationForm} message RegistrationForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegistrationForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && message.hasOwnProperty("id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && message.hasOwnProperty("name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.surname != null && message.hasOwnProperty("surname"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.surname);
            if (message.middlename != null && message.hasOwnProperty("middlename"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.middlename);
            if (message.phone != null && message.hasOwnProperty("phone"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.phone);
            if (message.email != null && message.hasOwnProperty("email"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.email);
            if (message.password != null && message.hasOwnProperty("password"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.password);
            if (message.checkpassword != null && message.hasOwnProperty("checkpassword"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.checkpassword);
            if (message.country != null && message.hasOwnProperty("country"))
                $root.CountrySearchResult.encode(message.country, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.callingCode != null && message.hasOwnProperty("callingCode"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.callingCode);
            return writer;
        };
    
        /**
         * Encodes the specified RegistrationForm message, length delimited. Does not implicitly {@link RegistrationForm.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RegistrationForm
         * @static
         * @param {IRegistrationForm} message RegistrationForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegistrationForm.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a RegistrationForm message from the specified reader or buffer.
         * @function decode
         * @memberof RegistrationForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RegistrationForm} RegistrationForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegistrationForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RegistrationForm();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.surname = reader.string();
                    break;
                case 4:
                    message.middlename = reader.string();
                    break;
                case 5:
                    message.phone = reader.string();
                    break;
                case 6:
                    message.email = reader.string();
                    break;
                case 7:
                    message.password = reader.string();
                    break;
                case 8:
                    message.checkpassword = reader.string();
                    break;
                case 9:
                    message.country = $root.CountrySearchResult.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.callingCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a RegistrationForm message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RegistrationForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RegistrationForm} RegistrationForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegistrationForm.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a RegistrationForm message.
         * @function verify
         * @memberof RegistrationForm
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegistrationForm.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.surname != null && message.hasOwnProperty("surname"))
                if (!$util.isString(message.surname))
                    return "surname: string expected";
            if (message.middlename != null && message.hasOwnProperty("middlename"))
                if (!$util.isString(message.middlename))
                    return "middlename: string expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            if (message.checkpassword != null && message.hasOwnProperty("checkpassword"))
                if (!$util.isString(message.checkpassword))
                    return "checkpassword: string expected";
            if (message.country != null && message.hasOwnProperty("country")) {
                var error = $root.CountrySearchResult.verify(message.country);
                if (error)
                    return "country." + error;
            }
            if (message.callingCode != null && message.hasOwnProperty("callingCode"))
                if (!$util.isString(message.callingCode))
                    return "callingCode: string expected";
            return null;
        };
    
        /**
         * Creates a RegistrationForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RegistrationForm
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RegistrationForm} RegistrationForm
         */
        RegistrationForm.fromObject = function fromObject(object) {
            if (object instanceof $root.RegistrationForm)
                return object;
            var message = new $root.RegistrationForm();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            if (object.surname != null)
                message.surname = String(object.surname);
            if (object.middlename != null)
                message.middlename = String(object.middlename);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.email != null)
                message.email = String(object.email);
            if (object.password != null)
                message.password = String(object.password);
            if (object.checkpassword != null)
                message.checkpassword = String(object.checkpassword);
            if (object.country != null) {
                if (typeof object.country !== "object")
                    throw TypeError(".RegistrationForm.country: object expected");
                message.country = $root.CountrySearchResult.fromObject(object.country);
            }
            if (object.callingCode != null)
                message.callingCode = String(object.callingCode);
            return message;
        };
    
        /**
         * Creates a plain object from a RegistrationForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RegistrationForm
         * @static
         * @param {RegistrationForm} message RegistrationForm
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegistrationForm.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
                object.surname = "";
                object.middlename = "";
                object.phone = "";
                object.email = "";
                object.password = "";
                object.checkpassword = "";
                object.country = null;
                object.callingCode = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.surname != null && message.hasOwnProperty("surname"))
                object.surname = message.surname;
            if (message.middlename != null && message.hasOwnProperty("middlename"))
                object.middlename = message.middlename;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            if (message.checkpassword != null && message.hasOwnProperty("checkpassword"))
                object.checkpassword = message.checkpassword;
            if (message.country != null && message.hasOwnProperty("country"))
                object.country = $root.CountrySearchResult.toObject(message.country, options);
            if (message.callingCode != null && message.hasOwnProperty("callingCode"))
                object.callingCode = message.callingCode;
            return object;
        };
    
        /**
         * Converts this RegistrationForm to JSON.
         * @function toJSON
         * @memberof RegistrationForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegistrationForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return RegistrationForm;
    })();
    
    $root.Recovery = (function() {
    
        /**
         * Properties of a Recovery.
         * @exports IRecovery
         * @interface IRecovery
         * @property {string|null} [email] Recovery email
         * @property {string|null} [callBackUrl] Recovery callBackUrl
         */
    
        /**
         * Constructs a new Recovery.
         * @exports Recovery
         * @classdesc Represents a Recovery.
         * @implements IRecovery
         * @constructor
         * @param {IRecovery=} [properties] Properties to set
         */
        function Recovery(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Recovery email.
         * @member {string} email
         * @memberof Recovery
         * @instance
         */
        Recovery.prototype.email = "";
    
        /**
         * Recovery callBackUrl.
         * @member {string} callBackUrl
         * @memberof Recovery
         * @instance
         */
        Recovery.prototype.callBackUrl = "";
    
        /**
         * Creates a new Recovery instance using the specified properties.
         * @function create
         * @memberof Recovery
         * @static
         * @param {IRecovery=} [properties] Properties to set
         * @returns {Recovery} Recovery instance
         */
        Recovery.create = function create(properties) {
            return new Recovery(properties);
        };
    
        /**
         * Encodes the specified Recovery message. Does not implicitly {@link Recovery.verify|verify} messages.
         * @function encode
         * @memberof Recovery
         * @static
         * @param {IRecovery} message Recovery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Recovery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && message.hasOwnProperty("email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
            if (message.callBackUrl != null && message.hasOwnProperty("callBackUrl"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.callBackUrl);
            return writer;
        };
    
        /**
         * Encodes the specified Recovery message, length delimited. Does not implicitly {@link Recovery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Recovery
         * @static
         * @param {IRecovery} message Recovery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Recovery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Recovery message from the specified reader or buffer.
         * @function decode
         * @memberof Recovery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Recovery} Recovery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Recovery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Recovery();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.email = reader.string();
                    break;
                case 2:
                    message.callBackUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Recovery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Recovery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Recovery} Recovery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Recovery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Recovery message.
         * @function verify
         * @memberof Recovery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Recovery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.callBackUrl != null && message.hasOwnProperty("callBackUrl"))
                if (!$util.isString(message.callBackUrl))
                    return "callBackUrl: string expected";
            return null;
        };
    
        /**
         * Creates a Recovery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Recovery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Recovery} Recovery
         */
        Recovery.fromObject = function fromObject(object) {
            if (object instanceof $root.Recovery)
                return object;
            var message = new $root.Recovery();
            if (object.email != null)
                message.email = String(object.email);
            if (object.callBackUrl != null)
                message.callBackUrl = String(object.callBackUrl);
            return message;
        };
    
        /**
         * Creates a plain object from a Recovery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Recovery
         * @static
         * @param {Recovery} message Recovery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Recovery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.email = "";
                object.callBackUrl = "";
            }
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.callBackUrl != null && message.hasOwnProperty("callBackUrl"))
                object.callBackUrl = message.callBackUrl;
            return object;
        };
    
        /**
         * Converts this Recovery to JSON.
         * @function toJSON
         * @memberof Recovery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Recovery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Recovery;
    })();
    
    $root.ChangePassword = (function() {
    
        /**
         * Properties of a ChangePassword.
         * @exports IChangePassword
         * @interface IChangePassword
         * @property {Uint8Array|null} [id] ChangePassword id
         * @property {string|null} [password] ChangePassword password
         * @property {string|null} [checkpassword] ChangePassword checkpassword
         */
    
        /**
         * Constructs a new ChangePassword.
         * @exports ChangePassword
         * @classdesc Represents a ChangePassword.
         * @implements IChangePassword
         * @constructor
         * @param {IChangePassword=} [properties] Properties to set
         */
        function ChangePassword(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * ChangePassword id.
         * @member {Uint8Array} id
         * @memberof ChangePassword
         * @instance
         */
        ChangePassword.prototype.id = $util.newBuffer([]);
    
        /**
         * ChangePassword password.
         * @member {string} password
         * @memberof ChangePassword
         * @instance
         */
        ChangePassword.prototype.password = "";
    
        /**
         * ChangePassword checkpassword.
         * @member {string} checkpassword
         * @memberof ChangePassword
         * @instance
         */
        ChangePassword.prototype.checkpassword = "";
    
        /**
         * Creates a new ChangePassword instance using the specified properties.
         * @function create
         * @memberof ChangePassword
         * @static
         * @param {IChangePassword=} [properties] Properties to set
         * @returns {ChangePassword} ChangePassword instance
         */
        ChangePassword.create = function create(properties) {
            return new ChangePassword(properties);
        };
    
        /**
         * Encodes the specified ChangePassword message. Does not implicitly {@link ChangePassword.verify|verify} messages.
         * @function encode
         * @memberof ChangePassword
         * @static
         * @param {IChangePassword} message ChangePassword message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePassword.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && message.hasOwnProperty("id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.password != null && message.hasOwnProperty("password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            if (message.checkpassword != null && message.hasOwnProperty("checkpassword"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.checkpassword);
            return writer;
        };
    
        /**
         * Encodes the specified ChangePassword message, length delimited. Does not implicitly {@link ChangePassword.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ChangePassword
         * @static
         * @param {IChangePassword} message ChangePassword message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePassword.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a ChangePassword message from the specified reader or buffer.
         * @function decode
         * @memberof ChangePassword
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ChangePassword} ChangePassword
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePassword.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChangePassword();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                case 3:
                    message.checkpassword = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a ChangePassword message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ChangePassword
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ChangePassword} ChangePassword
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePassword.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a ChangePassword message.
         * @function verify
         * @memberof ChangePassword
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangePassword.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            if (message.checkpassword != null && message.hasOwnProperty("checkpassword"))
                if (!$util.isString(message.checkpassword))
                    return "checkpassword: string expected";
            return null;
        };
    
        /**
         * Creates a ChangePassword message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ChangePassword
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ChangePassword} ChangePassword
         */
        ChangePassword.fromObject = function fromObject(object) {
            if (object instanceof $root.ChangePassword)
                return object;
            var message = new $root.ChangePassword();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.password != null)
                message.password = String(object.password);
            if (object.checkpassword != null)
                message.checkpassword = String(object.checkpassword);
            return message;
        };
    
        /**
         * Creates a plain object from a ChangePassword message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ChangePassword
         * @static
         * @param {ChangePassword} message ChangePassword
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangePassword.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.password = "";
                object.checkpassword = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            if (message.checkpassword != null && message.hasOwnProperty("checkpassword"))
                object.checkpassword = message.checkpassword;
            return object;
        };
    
        /**
         * Converts this ChangePassword to JSON.
         * @function toJSON
         * @memberof ChangePassword
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangePassword.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return ChangePassword;
    })();
    
    $root.ChangePasswordResult = (function() {
    
        /**
         * Properties of a ChangePasswordResult.
         * @exports IChangePasswordResult
         * @interface IChangePasswordResult
         * @property {string|null} [callBackUrl] ChangePasswordResult callBackUrl
         */
    
        /**
         * Constructs a new ChangePasswordResult.
         * @exports ChangePasswordResult
         * @classdesc Represents a ChangePasswordResult.
         * @implements IChangePasswordResult
         * @constructor
         * @param {IChangePasswordResult=} [properties] Properties to set
         */
        function ChangePasswordResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * ChangePasswordResult callBackUrl.
         * @member {string} callBackUrl
         * @memberof ChangePasswordResult
         * @instance
         */
        ChangePasswordResult.prototype.callBackUrl = "";
    
        /**
         * Creates a new ChangePasswordResult instance using the specified properties.
         * @function create
         * @memberof ChangePasswordResult
         * @static
         * @param {IChangePasswordResult=} [properties] Properties to set
         * @returns {ChangePasswordResult} ChangePasswordResult instance
         */
        ChangePasswordResult.create = function create(properties) {
            return new ChangePasswordResult(properties);
        };
    
        /**
         * Encodes the specified ChangePasswordResult message. Does not implicitly {@link ChangePasswordResult.verify|verify} messages.
         * @function encode
         * @memberof ChangePasswordResult
         * @static
         * @param {IChangePasswordResult} message ChangePasswordResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePasswordResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.callBackUrl != null && message.hasOwnProperty("callBackUrl"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.callBackUrl);
            return writer;
        };
    
        /**
         * Encodes the specified ChangePasswordResult message, length delimited. Does not implicitly {@link ChangePasswordResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ChangePasswordResult
         * @static
         * @param {IChangePasswordResult} message ChangePasswordResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePasswordResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a ChangePasswordResult message from the specified reader or buffer.
         * @function decode
         * @memberof ChangePasswordResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ChangePasswordResult} ChangePasswordResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePasswordResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChangePasswordResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.callBackUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a ChangePasswordResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ChangePasswordResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ChangePasswordResult} ChangePasswordResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePasswordResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a ChangePasswordResult message.
         * @function verify
         * @memberof ChangePasswordResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangePasswordResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.callBackUrl != null && message.hasOwnProperty("callBackUrl"))
                if (!$util.isString(message.callBackUrl))
                    return "callBackUrl: string expected";
            return null;
        };
    
        /**
         * Creates a ChangePasswordResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ChangePasswordResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ChangePasswordResult} ChangePasswordResult
         */
        ChangePasswordResult.fromObject = function fromObject(object) {
            if (object instanceof $root.ChangePasswordResult)
                return object;
            var message = new $root.ChangePasswordResult();
            if (object.callBackUrl != null)
                message.callBackUrl = String(object.callBackUrl);
            return message;
        };
    
        /**
         * Creates a plain object from a ChangePasswordResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ChangePasswordResult
         * @static
         * @param {ChangePasswordResult} message ChangePasswordResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangePasswordResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.callBackUrl = "";
            if (message.callBackUrl != null && message.hasOwnProperty("callBackUrl"))
                object.callBackUrl = message.callBackUrl;
            return object;
        };
    
        /**
         * Converts this ChangePasswordResult to JSON.
         * @function toJSON
         * @memberof ChangePasswordResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangePasswordResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return ChangePasswordResult;
    })();
    
    $root.UserInviteExist = (function() {
    
        /**
         * Properties of a UserInviteExist.
         * @exports IUserInviteExist
         * @interface IUserInviteExist
         * @property {boolean|null} [exist] UserInviteExist exist
         */
    
        /**
         * Constructs a new UserInviteExist.
         * @exports UserInviteExist
         * @classdesc Represents a UserInviteExist.
         * @implements IUserInviteExist
         * @constructor
         * @param {IUserInviteExist=} [properties] Properties to set
         */
        function UserInviteExist(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * UserInviteExist exist.
         * @member {boolean} exist
         * @memberof UserInviteExist
         * @instance
         */
        UserInviteExist.prototype.exist = false;
    
        /**
         * Creates a new UserInviteExist instance using the specified properties.
         * @function create
         * @memberof UserInviteExist
         * @static
         * @param {IUserInviteExist=} [properties] Properties to set
         * @returns {UserInviteExist} UserInviteExist instance
         */
        UserInviteExist.create = function create(properties) {
            return new UserInviteExist(properties);
        };
    
        /**
         * Encodes the specified UserInviteExist message. Does not implicitly {@link UserInviteExist.verify|verify} messages.
         * @function encode
         * @memberof UserInviteExist
         * @static
         * @param {IUserInviteExist} message UserInviteExist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserInviteExist.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.exist != null && message.hasOwnProperty("exist"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.exist);
            return writer;
        };
    
        /**
         * Encodes the specified UserInviteExist message, length delimited. Does not implicitly {@link UserInviteExist.verify|verify} messages.
         * @function encodeDelimited
         * @memberof UserInviteExist
         * @static
         * @param {IUserInviteExist} message UserInviteExist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserInviteExist.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a UserInviteExist message from the specified reader or buffer.
         * @function decode
         * @memberof UserInviteExist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {UserInviteExist} UserInviteExist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserInviteExist.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UserInviteExist();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.exist = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a UserInviteExist message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof UserInviteExist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {UserInviteExist} UserInviteExist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserInviteExist.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a UserInviteExist message.
         * @function verify
         * @memberof UserInviteExist
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserInviteExist.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.exist != null && message.hasOwnProperty("exist"))
                if (typeof message.exist !== "boolean")
                    return "exist: boolean expected";
            return null;
        };
    
        /**
         * Creates a UserInviteExist message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof UserInviteExist
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {UserInviteExist} UserInviteExist
         */
        UserInviteExist.fromObject = function fromObject(object) {
            if (object instanceof $root.UserInviteExist)
                return object;
            var message = new $root.UserInviteExist();
            if (object.exist != null)
                message.exist = Boolean(object.exist);
            return message;
        };
    
        /**
         * Creates a plain object from a UserInviteExist message. Also converts values to other types if specified.
         * @function toObject
         * @memberof UserInviteExist
         * @static
         * @param {UserInviteExist} message UserInviteExist
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserInviteExist.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.exist = false;
            if (message.exist != null && message.hasOwnProperty("exist"))
                object.exist = message.exist;
            return object;
        };
    
        /**
         * Converts this UserInviteExist to JSON.
         * @function toJSON
         * @memberof UserInviteExist
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserInviteExist.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return UserInviteExist;
    })();
    
    $root.CountrySearchResult = (function() {
    
        /**
         * Properties of a CountrySearchResult.
         * @exports ICountrySearchResult
         * @interface ICountrySearchResult
         * @property {Array.<Uint8Array>|null} [id] CountrySearchResult id
         * @property {string|null} [name] CountrySearchResult name
         * @property {string|null} [callingCode] CountrySearchResult callingCode
         */
    
        /**
         * Constructs a new CountrySearchResult.
         * @exports CountrySearchResult
         * @classdesc Represents a CountrySearchResult.
         * @implements ICountrySearchResult
         * @constructor
         * @param {ICountrySearchResult=} [properties] Properties to set
         */
        function CountrySearchResult(properties) {
            this.id = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CountrySearchResult id.
         * @member {Array.<Uint8Array>} id
         * @memberof CountrySearchResult
         * @instance
         */
        CountrySearchResult.prototype.id = $util.emptyArray;
    
        /**
         * CountrySearchResult name.
         * @member {string} name
         * @memberof CountrySearchResult
         * @instance
         */
        CountrySearchResult.prototype.name = "";
    
        /**
         * CountrySearchResult callingCode.
         * @member {string} callingCode
         * @memberof CountrySearchResult
         * @instance
         */
        CountrySearchResult.prototype.callingCode = "";
    
        /**
         * Creates a new CountrySearchResult instance using the specified properties.
         * @function create
         * @memberof CountrySearchResult
         * @static
         * @param {ICountrySearchResult=} [properties] Properties to set
         * @returns {CountrySearchResult} CountrySearchResult instance
         */
        CountrySearchResult.create = function create(properties) {
            return new CountrySearchResult(properties);
        };
    
        /**
         * Encodes the specified CountrySearchResult message. Does not implicitly {@link CountrySearchResult.verify|verify} messages.
         * @function encode
         * @memberof CountrySearchResult
         * @static
         * @param {ICountrySearchResult} message CountrySearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CountrySearchResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && message.id.length)
                for (var i = 0; i < message.id.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id[i]);
            if (message.name != null && message.hasOwnProperty("name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.callingCode != null && message.hasOwnProperty("callingCode"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.callingCode);
            return writer;
        };
    
        /**
         * Encodes the specified CountrySearchResult message, length delimited. Does not implicitly {@link CountrySearchResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CountrySearchResult
         * @static
         * @param {ICountrySearchResult} message CountrySearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CountrySearchResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CountrySearchResult message from the specified reader or buffer.
         * @function decode
         * @memberof CountrySearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CountrySearchResult} CountrySearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CountrySearchResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CountrySearchResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.id && message.id.length))
                        message.id = [];
                    message.id.push(reader.bytes());
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.callingCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CountrySearchResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CountrySearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CountrySearchResult} CountrySearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CountrySearchResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CountrySearchResult message.
         * @function verify
         * @memberof CountrySearchResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CountrySearchResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id")) {
                if (!Array.isArray(message.id))
                    return "id: array expected";
                for (var i = 0; i < message.id.length; ++i)
                    if (!(message.id[i] && typeof message.id[i].length === "number" || $util.isString(message.id[i])))
                        return "id: buffer[] expected";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.callingCode != null && message.hasOwnProperty("callingCode"))
                if (!$util.isString(message.callingCode))
                    return "callingCode: string expected";
            return null;
        };
    
        /**
         * Creates a CountrySearchResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CountrySearchResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CountrySearchResult} CountrySearchResult
         */
        CountrySearchResult.fromObject = function fromObject(object) {
            if (object instanceof $root.CountrySearchResult)
                return object;
            var message = new $root.CountrySearchResult();
            if (object.id) {
                if (!Array.isArray(object.id))
                    throw TypeError(".CountrySearchResult.id: array expected");
                message.id = [];
                for (var i = 0; i < object.id.length; ++i)
                    if (typeof object.id[i] === "string")
                        $util.base64.decode(object.id[i], message.id[i] = $util.newBuffer($util.base64.length(object.id[i])), 0);
                    else if (object.id[i].length)
                        message.id[i] = object.id[i];
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.callingCode != null)
                message.callingCode = String(object.callingCode);
            return message;
        };
    
        /**
         * Creates a plain object from a CountrySearchResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CountrySearchResult
         * @static
         * @param {CountrySearchResult} message CountrySearchResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CountrySearchResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.id = [];
            if (options.defaults) {
                object.name = "";
                object.callingCode = "";
            }
            if (message.id && message.id.length) {
                object.id = [];
                for (var j = 0; j < message.id.length; ++j)
                    object.id[j] = options.bytes === String ? $util.base64.encode(message.id[j], 0, message.id[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.id[j]) : message.id[j];
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.callingCode != null && message.hasOwnProperty("callingCode"))
                object.callingCode = message.callingCode;
            return object;
        };
    
        /**
         * Converts this CountrySearchResult to JSON.
         * @function toJSON
         * @memberof CountrySearchResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CountrySearchResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CountrySearchResult;
    })();
    
    $root.CountrySearchResults = (function() {
    
        /**
         * Properties of a CountrySearchResults.
         * @exports ICountrySearchResults
         * @interface ICountrySearchResults
         * @property {Array.<ICountrySearchResult>|null} [searchResult] CountrySearchResults searchResult
         */
    
        /**
         * Constructs a new CountrySearchResults.
         * @exports CountrySearchResults
         * @classdesc Represents a CountrySearchResults.
         * @implements ICountrySearchResults
         * @constructor
         * @param {ICountrySearchResults=} [properties] Properties to set
         */
        function CountrySearchResults(properties) {
            this.searchResult = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CountrySearchResults searchResult.
         * @member {Array.<ICountrySearchResult>} searchResult
         * @memberof CountrySearchResults
         * @instance
         */
        CountrySearchResults.prototype.searchResult = $util.emptyArray;
    
        /**
         * Creates a new CountrySearchResults instance using the specified properties.
         * @function create
         * @memberof CountrySearchResults
         * @static
         * @param {ICountrySearchResults=} [properties] Properties to set
         * @returns {CountrySearchResults} CountrySearchResults instance
         */
        CountrySearchResults.create = function create(properties) {
            return new CountrySearchResults(properties);
        };
    
        /**
         * Encodes the specified CountrySearchResults message. Does not implicitly {@link CountrySearchResults.verify|verify} messages.
         * @function encode
         * @memberof CountrySearchResults
         * @static
         * @param {ICountrySearchResults} message CountrySearchResults message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CountrySearchResults.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchResult != null && message.searchResult.length)
                for (var i = 0; i < message.searchResult.length; ++i)
                    $root.CountrySearchResult.encode(message.searchResult[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified CountrySearchResults message, length delimited. Does not implicitly {@link CountrySearchResults.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CountrySearchResults
         * @static
         * @param {ICountrySearchResults} message CountrySearchResults message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CountrySearchResults.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CountrySearchResults message from the specified reader or buffer.
         * @function decode
         * @memberof CountrySearchResults
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CountrySearchResults} CountrySearchResults
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CountrySearchResults.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CountrySearchResults();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.searchResult && message.searchResult.length))
                        message.searchResult = [];
                    message.searchResult.push($root.CountrySearchResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CountrySearchResults message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CountrySearchResults
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CountrySearchResults} CountrySearchResults
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CountrySearchResults.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CountrySearchResults message.
         * @function verify
         * @memberof CountrySearchResults
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CountrySearchResults.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.searchResult != null && message.hasOwnProperty("searchResult")) {
                if (!Array.isArray(message.searchResult))
                    return "searchResult: array expected";
                for (var i = 0; i < message.searchResult.length; ++i) {
                    var error = $root.CountrySearchResult.verify(message.searchResult[i]);
                    if (error)
                        return "searchResult." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a CountrySearchResults message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CountrySearchResults
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CountrySearchResults} CountrySearchResults
         */
        CountrySearchResults.fromObject = function fromObject(object) {
            if (object instanceof $root.CountrySearchResults)
                return object;
            var message = new $root.CountrySearchResults();
            if (object.searchResult) {
                if (!Array.isArray(object.searchResult))
                    throw TypeError(".CountrySearchResults.searchResult: array expected");
                message.searchResult = [];
                for (var i = 0; i < object.searchResult.length; ++i) {
                    if (typeof object.searchResult[i] !== "object")
                        throw TypeError(".CountrySearchResults.searchResult: object expected");
                    message.searchResult[i] = $root.CountrySearchResult.fromObject(object.searchResult[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a CountrySearchResults message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CountrySearchResults
         * @static
         * @param {CountrySearchResults} message CountrySearchResults
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CountrySearchResults.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.searchResult = [];
            if (message.searchResult && message.searchResult.length) {
                object.searchResult = [];
                for (var j = 0; j < message.searchResult.length; ++j)
                    object.searchResult[j] = $root.CountrySearchResult.toObject(message.searchResult[j], options);
            }
            return object;
        };
    
        /**
         * Converts this CountrySearchResults to JSON.
         * @function toJSON
         * @memberof CountrySearchResults
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CountrySearchResults.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CountrySearchResults;
    })();
    
    /**
     * ETeqServices enum.
     * @exports ETeqServices
     * @enum {string}
     * @property {number} BackOffice=10 BackOffice value
     * @property {number} ManufacturerOffice=20 ManufacturerOffice value
     * @property {number} PharmacyOffice=30 PharmacyOffice value
     * @property {number} RegionalManagerOffice=40 RegionalManagerOffice value
     */
    $root.ETeqServices = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[10] = "BackOffice"] = 10;
        values[valuesById[20] = "ManufacturerOffice"] = 20;
        values[valuesById[30] = "PharmacyOffice"] = 30;
        values[valuesById[40] = "RegionalManagerOffice"] = 40;
        return values;
    })();
    
    $root.AvailableRoute = (function() {
    
        /**
         * Properties of an AvailableRoute.
         * @exports IAvailableRoute
         * @interface IAvailableRoute
         * @property {ETeqServices|null} [teqService] AvailableRoute teqService
         * @property {string|null} [subroute] AvailableRoute subroute
         */
    
        /**
         * Constructs a new AvailableRoute.
         * @exports AvailableRoute
         * @classdesc Represents an AvailableRoute.
         * @implements IAvailableRoute
         * @constructor
         * @param {IAvailableRoute=} [properties] Properties to set
         */
        function AvailableRoute(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * AvailableRoute teqService.
         * @member {ETeqServices} teqService
         * @memberof AvailableRoute
         * @instance
         */
        AvailableRoute.prototype.teqService = 10;
    
        /**
         * AvailableRoute subroute.
         * @member {string} subroute
         * @memberof AvailableRoute
         * @instance
         */
        AvailableRoute.prototype.subroute = "";
    
        /**
         * Creates a new AvailableRoute instance using the specified properties.
         * @function create
         * @memberof AvailableRoute
         * @static
         * @param {IAvailableRoute=} [properties] Properties to set
         * @returns {AvailableRoute} AvailableRoute instance
         */
        AvailableRoute.create = function create(properties) {
            return new AvailableRoute(properties);
        };
    
        /**
         * Encodes the specified AvailableRoute message. Does not implicitly {@link AvailableRoute.verify|verify} messages.
         * @function encode
         * @memberof AvailableRoute
         * @static
         * @param {IAvailableRoute} message AvailableRoute message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableRoute.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.teqService != null && message.hasOwnProperty("teqService"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.teqService);
            if (message.subroute != null && message.hasOwnProperty("subroute"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.subroute);
            return writer;
        };
    
        /**
         * Encodes the specified AvailableRoute message, length delimited. Does not implicitly {@link AvailableRoute.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AvailableRoute
         * @static
         * @param {IAvailableRoute} message AvailableRoute message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableRoute.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an AvailableRoute message from the specified reader or buffer.
         * @function decode
         * @memberof AvailableRoute
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {AvailableRoute} AvailableRoute
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableRoute.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AvailableRoute();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.teqService = reader.int32();
                    break;
                case 2:
                    message.subroute = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an AvailableRoute message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AvailableRoute
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AvailableRoute} AvailableRoute
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableRoute.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an AvailableRoute message.
         * @function verify
         * @memberof AvailableRoute
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AvailableRoute.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.teqService != null && message.hasOwnProperty("teqService"))
                switch (message.teqService) {
                default:
                    return "teqService: enum value expected";
                case 10:
                case 20:
                case 30:
                case 40:
                    break;
                }
            if (message.subroute != null && message.hasOwnProperty("subroute"))
                if (!$util.isString(message.subroute))
                    return "subroute: string expected";
            return null;
        };
    
        /**
         * Creates an AvailableRoute message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof AvailableRoute
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {AvailableRoute} AvailableRoute
         */
        AvailableRoute.fromObject = function fromObject(object) {
            if (object instanceof $root.AvailableRoute)
                return object;
            var message = new $root.AvailableRoute();
            switch (object.teqService) {
            case "BackOffice":
            case 10:
                message.teqService = 10;
                break;
            case "ManufacturerOffice":
            case 20:
                message.teqService = 20;
                break;
            case "PharmacyOffice":
            case 30:
                message.teqService = 30;
                break;
            case "RegionalManagerOffice":
            case 40:
                message.teqService = 40;
                break;
            }
            if (object.subroute != null)
                message.subroute = String(object.subroute);
            return message;
        };
    
        /**
         * Creates a plain object from an AvailableRoute message. Also converts values to other types if specified.
         * @function toObject
         * @memberof AvailableRoute
         * @static
         * @param {AvailableRoute} message AvailableRoute
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AvailableRoute.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.teqService = options.enums === String ? "BackOffice" : 10;
                object.subroute = "";
            }
            if (message.teqService != null && message.hasOwnProperty("teqService"))
                object.teqService = options.enums === String ? $root.ETeqServices[message.teqService] : message.teqService;
            if (message.subroute != null && message.hasOwnProperty("subroute"))
                object.subroute = message.subroute;
            return object;
        };
    
        /**
         * Converts this AvailableRoute to JSON.
         * @function toJSON
         * @memberof AvailableRoute
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AvailableRoute.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return AvailableRoute;
    })();
    
    $root.AvailableRoutes = (function() {
    
        /**
         * Properties of an AvailableRoutes.
         * @exports IAvailableRoutes
         * @interface IAvailableRoutes
         * @property {Array.<IAvailableRoute>|null} [items] AvailableRoutes items
         */
    
        /**
         * Constructs a new AvailableRoutes.
         * @exports AvailableRoutes
         * @classdesc Represents an AvailableRoutes.
         * @implements IAvailableRoutes
         * @constructor
         * @param {IAvailableRoutes=} [properties] Properties to set
         */
        function AvailableRoutes(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * AvailableRoutes items.
         * @member {Array.<IAvailableRoute>} items
         * @memberof AvailableRoutes
         * @instance
         */
        AvailableRoutes.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new AvailableRoutes instance using the specified properties.
         * @function create
         * @memberof AvailableRoutes
         * @static
         * @param {IAvailableRoutes=} [properties] Properties to set
         * @returns {AvailableRoutes} AvailableRoutes instance
         */
        AvailableRoutes.create = function create(properties) {
            return new AvailableRoutes(properties);
        };
    
        /**
         * Encodes the specified AvailableRoutes message. Does not implicitly {@link AvailableRoutes.verify|verify} messages.
         * @function encode
         * @memberof AvailableRoutes
         * @static
         * @param {IAvailableRoutes} message AvailableRoutes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableRoutes.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.AvailableRoute.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified AvailableRoutes message, length delimited. Does not implicitly {@link AvailableRoutes.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AvailableRoutes
         * @static
         * @param {IAvailableRoutes} message AvailableRoutes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableRoutes.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an AvailableRoutes message from the specified reader or buffer.
         * @function decode
         * @memberof AvailableRoutes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {AvailableRoutes} AvailableRoutes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableRoutes.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AvailableRoutes();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.AvailableRoute.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an AvailableRoutes message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AvailableRoutes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AvailableRoutes} AvailableRoutes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableRoutes.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an AvailableRoutes message.
         * @function verify
         * @memberof AvailableRoutes
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AvailableRoutes.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.AvailableRoute.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates an AvailableRoutes message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof AvailableRoutes
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {AvailableRoutes} AvailableRoutes
         */
        AvailableRoutes.fromObject = function fromObject(object) {
            if (object instanceof $root.AvailableRoutes)
                return object;
            var message = new $root.AvailableRoutes();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".AvailableRoutes.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".AvailableRoutes.items: object expected");
                    message.items[i] = $root.AvailableRoute.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from an AvailableRoutes message. Also converts values to other types if specified.
         * @function toObject
         * @memberof AvailableRoutes
         * @static
         * @param {AvailableRoutes} message AvailableRoutes
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AvailableRoutes.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.AvailableRoute.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this AvailableRoutes to JSON.
         * @function toJSON
         * @memberof AvailableRoutes
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AvailableRoutes.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return AvailableRoutes;
    })();

    return $root;
});
