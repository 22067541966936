// extracted by mini-css-extract-plugin
export var sectionTitle = "Recovery-module--section-title--2LCRi";
export var sectionLogo = "Recovery-module--section-logo--3nazq";
export var section = "Recovery-module--section--39Vvo";
export var sectionWrapper = "Recovery-module--section-wrapper--2C6zX";
export var sectionForm = "Recovery-module--section-form--3Fplp";
export var sectionBlockInput = "Recovery-module--section-block-input--2qasJ";
export var sectionBlockButton = "Recovery-module--section-block-button--8B2oz";
export var sectionBlockText = "Recovery-module--section-block-text--2Dy8M";
export var sectionBlockLogo = "Recovery-module--section-block-logo--2S562";
export var sectionText = "Recovery-module--section-text--_FNkh";