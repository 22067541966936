import classNames from 'classnames'
import React, { MouseEventHandler, ReactElement } from 'react'

import LoaderSpinnerIcon from '../../Icons/LoaderSpinnerIcon'
import {
  buttonText,
  buttonTextBigger,
  buttonTextFullWidth,
  buttonTextIcon,
  buttonTextIconLeft,
  buttonTextIconRight,
  buttonTextLoading,
  buttonTextOutlined,
  buttonTextSimple,
  buttonTextTextual,
  buttonTextWhite,
} from './TextButton.module.scss'

interface ITextButtonProps {
  onClick?: MouseEventHandler
  text: string
  disabled?: boolean
  type?: string
  color?: 'white' | 'blue'
  size?: 'big'
  iconLeft?: JSX.Element
  iconRight?: JSX.Element
  fullWidth?: boolean
  variant?: 'outlined' | 'text' | 'contained' | 'link'
  loading?: boolean
}

type TextButtonProps = ITextButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>

const TextButton = (props: TextButtonProps): ReactElement<TextButtonProps> => {
  const buttonType = props.type || 'button'
  const { size, color, fullWidth, iconLeft, iconRight, variant, loading, ...rest } = props

  return (
    <button
      onClick={props.onClick}
      className={classNames(buttonText, {
        [buttonTextOutlined]: variant === 'outlined',
        [buttonTextWhite]: color === 'white',
        [buttonTextBigger]: size === 'big',
        [buttonTextIcon]: iconLeft || iconRight,
        [buttonTextFullWidth]: fullWidth,
        [buttonTextSimple]: variant === 'link',
        [buttonTextTextual]: variant === 'text',
        [buttonTextLoading]: loading,
      })}
      type={buttonType}
      disabled={props.disabled}
      {...rest}
    >
      {loading ? (
        <LoaderSpinnerIcon />
      ) : (
        <>
          {iconLeft && <span className={buttonTextIconLeft}>{iconLeft}</span>}
          {props.text}
          {iconRight && <span className={buttonTextIconRight}>{iconRight}</span>}
        </>
      )}
    </button>
  )
}

export default TextButton
