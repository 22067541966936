// extracted by mini-css-extract-plugin
export var modal = "ChangePassword-module--modal--2mC65";
export var modalTitle = "ChangePassword-module--modal-title--2rtMR";
export var sectionTitle = "ChangePassword-module--section-title--2Wu5C";
export var sectionLogo = "ChangePassword-module--section-logo--aAEyb";
export var section = "ChangePassword-module--section--10E9J";
export var sectionWrapper = "ChangePassword-module--section-wrapper--3sh6w";
export var sectionForm = "ChangePassword-module--section-form--1-3aZ";
export var sectionBlockInput = "ChangePassword-module--section-block-input--gjSuq";
export var sectionBlockButton = "ChangePassword-module--section-block-button--1HPNN";
export var sectionBlockLogo = "ChangePassword-module--section-block-logo--2j2Dt";
export var sectionInput = "ChangePassword-module--section-input--17IXD";
export var modalButton = "ChangePassword-module--modal-button--3mZqM";