import { navigate, withPrefix } from 'gatsby'
import React, { ReactElement } from 'react'
import TextButton from 'shared/components/Buttons/TextButton'
import Logo from 'shared/components/Icons/Logo'

import {
  section,
  sectionBlockButton,
  sectionBlockLogo,
  sectionBlockText,
  sectionLogo,
  sectionText,
  sectionTitle,
  sectionWrapper,
} from './RecoveryProcessing.module.scss'

interface IRecoveryProcessingProps {
  path: string
  url?: string
}

const RecoveryProcessing = (props: IRecoveryProcessingProps): ReactElement<IRecoveryProcessingProps> => (
  <section className={section}>
    <div className={sectionWrapper}>
      <div className={sectionBlockLogo}>
        <Logo class={sectionLogo} />
      </div>
      <h1 className={sectionTitle}>Востановление пароля</h1>
      <div className={sectionBlockText}>
        <p className={sectionText}>
          Мы&nbsp;отправили письмо на&nbsp;ваш электронный адрес. Пожалуйста, перейдите по&nbsp;указанной в&nbsp;письме
          ссылке для смены пароля.
        </p>
      </div>
      <div className={sectionBlockButton}>
        <TextButton
          onClick={() => navigate(withPrefix(`/recovery/`))}
          text="Отправить повторно"
          fullWidth={true}
          size="big"
          variant="text"
        />
      </div>
    </div>
  </section>
)

export default RecoveryProcessing
