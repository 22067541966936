import React from 'react'
import { Field, FormErrors, InjectedFormProps, reduxForm } from 'redux-form'
import TextButton from 'shared/components/Buttons/TextButton'
import CustomInput from 'shared/components/CustomInput'

import { errorRequiredField, passwordMismatch, passwordMustBeAtLeast } from '../../constants/validationText'
import { IChangePassword } from '../../proto/models'
import { changePassword } from '../../reducers/registration/registrationReducer'
import { sectionBlockButton, sectionBlockInput, sectionForm, sectionInput } from './ChangePassword.module.scss'

interface IChangePasswordProps {
  id?: string
  changeCallback?: () => void
}

type ReduxFormProps = IChangePasswordProps & InjectedFormProps<IChangePassword, IChangePasswordProps>

const Login = (props: ReduxFormProps) => {
  const { handleSubmit, submitting } = props

  return (
    <form onSubmit={handleSubmit} className={sectionForm}>
      <div className={sectionBlockInput}>
        <div className={sectionInput}>
          <Field
            name="password"
            label="Новый пароль"
            component={CustomInput}
            placeholder="Введите пароль"
            isReserveLabelError={true}
            type="password"
            size="large"
          />
        </div>
        <div className={sectionInput}>
          <Field
            name="checkpassword"
            label="Повторите пароль"
            component={CustomInput}
            placeholder="Введите пароль"
            isReserveLabelError={true}
            type="password"
            size="large"
          />
        </div>
      </div>
      <div className={sectionBlockButton}>
        <TextButton disabled={submitting} type="submit" text="Сохранить" fullWidth={true} size="big" />
      </div>
    </form>
  )
}

const validate = (values: IChangePassword): FormErrors<IChangePassword, string> => {
  const errors: FormErrors<IChangePassword, string> = {}

  if (!values.password) {
    errors.password = errorRequiredField
  } else if (values.password?.length < 6) {
    errors.password = passwordMustBeAtLeast
  }

  if (values.password !== values.checkpassword) {
    errors.checkpassword = passwordMismatch
  }

  return errors
}

export default reduxForm<IChangePassword, IChangePasswordProps>({
  form: 'change-password',
  validate,
  onSubmit: async (values, dispatch, props) => {
    await dispatch(changePassword(props.id, values))
    props.changeCallback()
  },
})(Login)
