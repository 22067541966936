import { Dispatch } from 'redux'

import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { createReducer } from '@reduxjs/toolkit'

import {
  ChangePassword,
  ChangePasswordResult,
  IChangePassword,
  IChangePasswordResult,
  IRecovery,
  IRegistrationForm,
  IUserInviteExist,
  Recovery,
  RegistrationForm,
  UserInviteExist,
} from '../../proto/models'
import { navigate, withPrefix } from 'gatsby'

const url = 'user-registration'

export interface IRegistrationState {
  form: IRegistrationForm
  checkInProgress: boolean
}

const initialState: IRegistrationState = {
  form: {
    id: null,
    name: null,
    surname: null,
    middlename: null,
    phone: null,
    email: null,
    password: null,
  },
  checkInProgress: true,
}

export const register = (id: string, values: IRegistrationForm) => async (): Promise<void> => {
  const form = { id: GuidHelper.parse(id), ...values }

  await ProtoClient.put<IRegistrationForm>(
    url,
    RegistrationForm.create(form),
    RegistrationForm,
    RegistrationForm,
    'auth/api/'
  )
}

export const recovery = (values: IRecovery) => async (): Promise<void> => {
  await ProtoClient.post<IRecovery>('recovery/send-recovery-mail', Recovery.create(values), Recovery, null, 'auth/api/')
}

export const changePassword = (id: string, values: IChangePassword) => async (): Promise<void> => {
  const form: IChangePassword = { id: GuidHelper.parse(id), ...values }

  await ProtoClient.post<IChangePasswordResult>(
    'recovery/change-password',
    ChangePassword.create(form),
    ChangePassword,
    ChangePasswordResult,
    'auth/api/'
  )
}

const CHECK_USER_INVITE_EXIST = 'CHECK_USER_INVITE_EXIST'
export const checkUserInviteExist = (id: string) => async (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: CHECK_USER_INVITE_EXIST, payload: true })

  const res = await ProtoClient.get<IUserInviteExist>(url + '/invite-exist', UserInviteExist, { id: id }, 'auth/api/')

  if (res.exist) {
    dispatch({ type: CHECK_USER_INVITE_EXIST, payload: false })
  } else {
    navigate(withPrefix('/'))
  }
}

export default createReducer(initialState, {
  [CHECK_USER_INVITE_EXIST]: (state, action) => {
    state.checkInProgress = action.payload
  },
})
