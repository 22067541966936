import React from 'react'

import icon from '../../assets/img/logo.svg'

interface ILogoProps {
  class?: string
}

const Logo = (props: ILogoProps) => {
  return <img className={props.class} src={icon} alt="Logo" />
}

export default Logo
