import { navigate } from 'gatsby'
import React from 'react'
import { Field, FormErrors, InjectedFormProps, reduxForm } from 'redux-form'
import TextButton from 'shared/components/Buttons/TextButton'
import CustomInput from 'shared/components/CustomInput'
import emailValidationCheck from 'shared/utils/emailValidationCheck'

import { errorRequiredField, invalidRequiredFieldEmail } from '../../constants/validationText'
import { IRecovery } from '../../proto/models'
import { recovery } from '../../reducers/registration/registrationReducer'
import {
  sectionBlockButton,
  sectionBlockInput,
  sectionBlockText,
  sectionForm,
  sectionText,
} from './Recovery.module.scss'

type RecoveryProps = InjectedFormProps<IRecovery, never>

const Recovery = (props: RecoveryProps) => {
  const { handleSubmit, submitting } = props

  return (
    <form onSubmit={handleSubmit} className={sectionForm}>
      <div className={sectionBlockInput}>
        <Field
          name="email"
          label="Email"
          type="text"
          component={CustomInput}
          placeholder="Введите логин"
          isReserveLabelError={true}
          size="large"
        />
      </div>
      <div className={sectionBlockText}>
        <p className={sectionText}>Мы&nbsp;отправим инструкцию по&nbsp;восстановлению пароля вам на&nbsp;почту</p>
      </div>
      <div className={sectionBlockButton}>
        <TextButton disabled={submitting} type="submit" text="Восстановить" fullWidth={true} size="big" />
      </div>
    </form>
  )
}

const validate = (values: IRecovery): FormErrors<IRecovery, string> => {
  const errors: FormErrors<IRecovery, string> = {}

  if (!values.email) {
    errors.email = errorRequiredField
  } else if (!emailValidationCheck(values.email)) {
    errors.email = invalidRequiredFieldEmail
  }
  return errors
}

export default reduxForm<IRecovery, never>({
  form: 'recovery',
  validate,
  onSubmit: (values, dispatch, props) => {
    dispatch(recovery(values))
    navigate(`/recovery-processing`)
  },
})(Recovery)
