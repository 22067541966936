import { AuthService } from 'external/rp.ui/auth/services/authService'
import { AuthError } from 'external/rp.ui/utils/protoClient'
import { navigate } from 'gatsby'
import React from 'react'
import { Field, FormErrors, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form'
import TextButton from 'shared/components/Buttons/TextButton'
import CustomInput from 'shared/components/CustomInput'
import emailValidationCheck from 'shared/utils/emailValidationCheck'

import { errorRequiredField, invalidDataEntered, invalidRequiredFieldEmail } from '../../constants/validationText'
import { setLoadingButton } from '../../reducers/portalReducer'
import {
  sectionBlockButton,
  sectionBlockInput,
  sectionBlockLink,
  sectionError,
  sectionErrorText,
  sectionForm,
  sectionInput,
} from './LoginPage.module.scss'

interface ILoginOwmProps {
  callbackAuth: () => void
  loading: boolean
}

interface ILoginFormProps {
  username?: string
  password?: string
}

type ReduxFormProps = ILoginOwmProps & InjectedFormProps<ILoginFormProps, ILoginOwmProps>

const Login = (props: ReduxFormProps) => {
  const { handleSubmit, submitting, error } = props

  return (
    <form onSubmit={handleSubmit} className={sectionForm}>
      <div className={sectionBlockInput}>
        <div className={sectionInput}>
          <Field
            name="username"
            label="Email"
            component={CustomInput}
            placeholder="Введите Email"
            isReserveLabelError={true}
            error={!!error}
            size="large"
          />
        </div>
        <div className={sectionInput}>
          <Field
            name="password"
            label="Пароль"
            component={CustomInput}
            placeholder="Введите пароль"
            isReserveLabelError={true}
            type="password"
            error={!!error}
            size="large"
          />
        </div>
      </div>
      <div className={sectionError}>
        <span className={sectionErrorText}>{error}&nbsp;</span>
      </div>
      <div className={sectionBlockButton}>
        <TextButton type="submit" text="Вход" fullWidth={true} size="big" loading={props.loading} />
      </div>
      <div className={sectionBlockLink}>
        <TextButton
          onClick={() => navigate(`/recovery/`)}
          variant="text"
          text="Забыли пароль?"
          fullWidth={true}
          size="big"
        />
      </div>
    </form>
  )
}

const validate = (values: ILoginFormProps): FormErrors<ILoginFormProps, string> => {
  const errors: FormErrors<ILoginFormProps, string> = {}

  if (!values.username) {
    errors.username = errorRequiredField
  } else if (!emailValidationCheck(values.username)) {
    errors.username = invalidRequiredFieldEmail
  }

  if (!values.password) {
    errors.password = errorRequiredField
  }

  return errors
}

export default reduxForm<ILoginFormProps, ILoginOwmProps>({
  form: 'login',
  validate,
  onSubmit: async (values, dispatch, props) => {
    dispatch(setLoadingButton(true))
    try {
      await AuthService.Login(values)
      props.callbackAuth()
    } catch (error) {
      dispatch(setLoadingButton(false))
      if (error instanceof AuthError) {
        throw new SubmissionError({ _error: invalidDataEntered })
      }
    }
  },
})(Login)
