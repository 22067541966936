import React, { ChangeEventHandler } from 'react'
import { WrappedFieldInputProps } from 'redux-form'

import {
  customCheckbox,
  customCheckboxAnswerText,
  customCheckboxInput,
  customCheckboxLabel,
} from './CustomCheckbox.module.scss'

interface ICustomCheckboxProps {
  id: string
  checked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  input?: WrappedFieldInputProps
  textAnswer?: string
  name?: string
  value?: string
  readOnly?: boolean
  disabled?: boolean
}

export const CustomChechbox = React.forwardRef<HTMLInputElement, ICustomCheckboxProps>(
  (props: ICustomCheckboxProps, ref) => {
    return (
      <span className={customCheckbox}>
        <input
          ref={ref}
          className={customCheckboxInput}
          checked={props.checked}
          id={props.id}
          type="checkbox"
          onChange={props.onChange}
          name={props.name}
          value={props.value}
          readOnly={props.readOnly}
          disabled={props.disabled}
          {...props.input}
        />
        <label className={customCheckboxLabel} htmlFor={props.id}></label>
        {props.textAnswer && <span className={customCheckboxAnswerText}>{props.textAnswer}</span>}
      </span>
    )
  }
)

export default CustomChechbox
