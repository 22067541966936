import React, { ReactElement, useState } from 'react'
import Logo from 'shared/components/Icons/Logo'

import ChangePassword from './ChangePassword'
import ChangePasswordModal from './ChangePasswordModal'
import { section, sectionWrapper, sectionBlockLogo, sectionLogo, sectionTitle } from './ChangePassword.module.scss'

interface IChangePasswordProps {
  path: string
  id?: string
  url?: string
}

const ChangePasswordPage = (props: IChangePasswordProps): ReactElement<IChangePasswordProps> => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <section className={section}>
        <div className={sectionWrapper}>
          <div className={sectionBlockLogo}>
            <Logo class={sectionLogo} />
          </div>
          <h1 className={sectionTitle}>Укажите новый пароль</h1>
          <ChangePassword
            id={props.id}
            changeCallback={() => {
              setOpen(true)
            }}
          />
        </div>
      </section>
      <ChangePasswordModal onClose={() => setOpen(false)} url={props.url} open={open} />
    </>
  )
}

export default ChangePasswordPage
