import React from 'react'

interface ClientOnlyProps {
  children: React.ReactNode
}

//HACK: исправляет проблему с gatsby rehydration(https://www.joshwcomeau.com/react/the-perils-of-rehydration/)
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ClientOnly(props: ClientOnlyProps) {
  const { children } = props
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  return <> {children}</>
}

export default ClientOnly
