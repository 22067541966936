// extracted by mini-css-extract-plugin
export var customInputInput = "CustomAutocompleteInput-module--custom-input-input--3AD8D";
export var label = "CustomAutocompleteInput-module--label--ah2up";
export var icon = "CustomAutocompleteInput-module--icon--17szW";
export var list = "CustomAutocompleteInput-module--list--1pf49";
export var blockCustomInput = "CustomAutocompleteInput-module--block-custom-input--IkE2M";
export var customInput = "CustomAutocompleteInput-module--custom-input--1YAQr";
export var customInputSmall = "CustomAutocompleteInput-module--custom-input-small--1y2do";
export var customInputNormal = "CustomAutocompleteInput-module--custom-input-normal--24xfw";
export var customInputInputError = "CustomAutocompleteInput-module--custom-input-input-error--1xSl-";
export var wrapper = "CustomAutocompleteInput-module--wrapper--bIQX5";
export var labelError = "CustomAutocompleteInput-module--label-error--HPwal";
export var listItem = "CustomAutocompleteInput-module--list-item--vPn-B";
export var listText = "CustomAutocompleteInput-module--list-text--PjZWX";
export var iconArrownDown = "CustomAutocompleteInput-module--icon-arrown-down--1upGm";
export var iconMagnifier = "CustomAutocompleteInput-module--icon-magnifier--22gi1";