import React, { ReactElement } from 'react'

import Logo from 'shared/components/Icons/Logo'

import Recovery from './Recovery'
import { section, sectionBlockLogo, sectionLogo, sectionTitle, sectionWrapper } from './Recovery.module.scss'

interface IRecoveryPageProps {
  path: string
  url?: string
}

const RecoveryPage = (props: IRecoveryPageProps): ReactElement<IRecoveryPageProps> => (
  <section className={section}>
    <div className={sectionWrapper}>
      <div className={sectionBlockLogo}>
        <Logo class={sectionLogo} />
      </div>
      <h1 className={sectionTitle}>Востановление пароля</h1>
      <Recovery />
    </div>
  </section>
)

export default RecoveryPage
